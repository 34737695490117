require('./bootstrap');
require('./cookies/cookies');

window.equalHeight = function (group) {
    let tallest = 0;
    group.each(function () {
        let thisHeight = $(this).height();
        if (thisHeight > tallest) {
            tallest = thisHeight;
        }
    });
    group.height(tallest);
}

$(document).ready(function () {

    $('#sc-cc').cookieTrackingControl(ctc_settings);

    if ($('#clp').length > 0) {
        equalHeight($('.card-h5'));
        equalHeight($('.card-p'));
    }
    if ($('#plp').length > 0) {
        equalHeight($('.card-h5'));
        equalHeight($('.card-h6'));
        equalHeight($('.card-li'));
    }

    let pdp_swiper = new Swiper("#pdp-section-4 .related-products", {
        slidesPerView: 1,
        breakpoints: {
            576: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2,
                spaceBetween: 40,
            },
            992: {
                slidesPerView: 3,
                spaceBetween: 50,
            },
            1200: {
                slidesPerView: 4,
                spaceBetween: 60,
            },
        },
        spaceBetween: 0,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    let frontpage_swiper = new Swiper("#pdp-frontpage .related-products", {
        slidesPerView: 1,
        scrollbar: {
            el: ".swiper-scrollbar",
            hide: false,
            draggable: true,
        },
        breakpoints: {
            576: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            768: {
                slidesPerView: 2.5,
                spaceBetween: 40,
            },
            // 992: {
            //     slidesPerView: 3.1,
            //     spaceBetween: 50,
            // },
            // 1200: {
            //     slidesPerView: 4,
            //     spaceBetween: 60,
            // },
        },
        spaceBetween: 0,
        navigation: {
            nextEl: ".swiper-next",
            prevEl: ".swiper-prev",
        },
    });

    $('#pdp-section-1 select, #pdp-section-1 input[type=radio]').on('change', function () {

        $.ajax({
            url: '/' + $('html').attr('lang') + '/variant',
            type: 'post',
            dataType: 'json',
            headers: {
                "X-HTTP-Method-Override": "POST",
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
            },
            data: {
                product: $('#product').val(),
                color: $("input:radio[name^='color']:checked").val(),
                version: $('#attr-1 option:selected').val(),
            },
            success: function (data) {
                $('input:hidden#code').val(data.code);
                $('#variant').submit()
            },
            fail: function (data) {
            },
        });
    });

});